/* Use grid layout for 3 items per row */
.po_items_ho {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
    grid-gap: 20px; /* Space between each item */
    margin-top: 20px;
}

.po_item {
    position: relative;
    background: var(--secondary-color);
    padding: 6px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden; /* Ensures content doesn't overflow outside the item */
}

.po_item img {
    max-width: 100%;
    height: auto;
    display: block; /* Ensures proper image display */
}

/* Updated .content styles */
.po_item .content {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7); /* Slightly transparent dark overlay */
    color: white; /* Text color for visibility */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* Stack the description and buttons vertically */
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25); /* Adds a hover shadow effect */
}

.po_item:hover .content {
    opacity: 1; /* Show content on hover */
}

/* Updated styling for the description */
.po_item .content p {
    margin-bottom: 20px; /* Space between the description and buttons */
    font-size: 1.2rem; /* Adjust font size for better readability */
}

/* Optional styling for the links inside .content */
.po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
    color: var(--text-color);
    margin: 5px 0; /* Space between the buttons */
    display: inline-block;
}

.po_item .content a:hover {
    text-decoration: none;
}

/* Button container to vertically align the buttons */
.po_item .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Responsive Adjustments */
@media (max-width: 991.98px) {
    .po_items_ho {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row on tablets */
    }
}

@media (max-width: 767.98px) {
    .po_items_ho {
        grid-template-columns: 1fr; /* 1 item per row on mobile */
    }
    
    .po_item .content p {
        font-size: 1rem; /* Reduce font size on mobile */
    }
    
    .po_item .content a {
        font-size: 0.9rem; /* Reduce button text size on mobile */
        padding: 6px 12px; /* Adjust padding for smaller buttons */
    }
}